import React from "react";
import {
  Box,
  Paper,
  Tab, Tabs, Typography
} from "@mui/material";
import { mail, upArrow, down } from "./assets";
import OpenBakeryModalController, { Props } from "./OpenBakeryModalController.web";
export default class Support extends OpenBakeryModalController {
  constructor(props: Props) {
    super(props);


  }

  render() {
    const faqs = [
      {
        question: "Can I customise my baked goods?",
        answer:
          "Yes, many of our specialty items can be customized to suit your preferences. Simply select the product you want and look for customization options such as adding or removing specific ingredients.",
      },
      {
        question: "What is the process for generating a quote for a client?",
        answer:
          "After selecting a client, go to the 'Quotes' section and click 'Create New Quote.' You'll be guided through a detailed survey to gather all necessary information about the model, bakers, charges, and logistic requirements. Once completed, save the quote to review or edit before finalizing.",
      },
      {
        question: "Can I share the menu or a baker’s profile with others?",
        answer:
          "Yes, you can easily share both! Each menu item and baker's profile has a share button. Just tap it to send the details via your preferred platform.",
      },
      {
        question: "Do you deliver orders to my address?",
        answer:
          "No, our current concept focuses on pick-up only. You can collect your order directly from the baker’s location as listed in the app."
      },
      {
        question: "Where can I find the location of the baker?",
        answer:
          "You can find the baker's location in their profile under the 'Chef's location' section. Click on it to view a map, opening hours, and additional details about the bakery.",
      },
    ];
    return (
      <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: '70%', color: 'white', }}>
          <Tabs
            value={this.state.activeTab}
            onChange={this.handleTabChange}
            data-test-id="tabs"
            centered
            textColor="inherit"
            TabIndicatorProps={{
              style: { backgroundColor: 'yellow' },
            }}
            sx={{ display: 'flex', justifyContent: 'space-around', alignItems: "center" }}
          >
            <Tab label="FAQ ´s" sx={{ mx: 20 }} />
            <Tab label="Contact Us" sx={{ mx: 20 }} />
          </Tabs>

          <Box>
            {this.state.activeTab === 0 && (
              <Box sx={{ width: '100%', height: "100%", overflowY: 'auto', marginTop: "10px" }}>
                <div style={{ color: "#fff", padding: "20px" }}>
                  <Typography style={{ color: "#C2B30A", fontSize: "24px", fontWeight: 700, marginBottom: "20px" }}>FAQ's</Typography>
                  <div>
                    {faqs.map((faq, index) => (
                      <div key={index} style={{ marginBottom: "15px" }}>
                        <Typography
                          onClick={() => this.toggleFAQ(index)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            cursor: "pointer",
                            backgroundColor: "#44403C",
                            padding: "15px 15px 10px 15px",
                            borderRadius: this.state.openFAQ === index ? "8px 8px 0 0" : "8px",
                            fontSize: "16px",
                            fontWeight: 700
                          }}
                        >
                          {faq.question}
                          <span
                          >
                            <img src={this.state.openFAQ === index ? upArrow : down} />
                          </span>
                        </Typography>
                        {this.state.openFAQ === index && (
                          <div style={{ backgroundColor: "#44403C", borderRadius: "0 0 8px 8px", }}>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                              <div style={{ height: '1px', backgroundColor: '#C2B30A', marginBottom: '10px', width: "97%" }}></div>
                            </div>
                            <Typography
                              style={{
                                padding: "10px 15px 15px 15px",
                                fontSize: "14px",
                                fontWeight: 400
                              }}
                            >
                              {faq.answer}
                            </Typography>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </Box>
            )}
            {this.state.activeTab === 1 && (
              <div style={{ width: "100%", display: "flex", justifyContent: "center", height: "calc(100vh - 15rem)" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginTop: "5rem",
                    color: "#fff",
                    height: "50%",
                    width: "50%"
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 700,
                      fontSize: "24px",
                      color: "rgba(194, 179, 10, 1)",
                    }}
                  >
                    Contact <span style={{ color: "rgba(255, 255, 255, 1)" }}>Us</span>
                  </Typography>
                  <Paper
                    elevation={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      backgroundColor: "rgba(87, 83, 78, 1)",
                      padding: "1rem",
                      borderRadius: "8px",
                      mt: 3,
                      width: "100%",
                    }}
                  >
                    <img src={mail} alt="mail" style={{ marginRight: 10 }} />
                    <Typography sx={{ color: "#fff", fontSize: "1rem" }}>
                      contact@amateurbakers.com
                    </Typography>
                  </Paper>
                </Box>
              </div>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
}