import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import UserProfile from "./Profile.web";
import { Box, Typography } from "@mui/material";
import SettingsController, { Props, } from "./SettingsController.web";
import ChangePassword from "./ChangePassword.web";
import DeleteAccount from "./DeleteAccount.web";
import ChangeEmail from "./ChangeEmail.web";
export default class UserSettings extends SettingsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Box sx={{
        width: "100%",
        overflowY: 'hidden',
        height: '100%',
      }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: '27px',
            fontWeight: 700,
          }}>
          <span
            style={{ color: "#ffffff", marginLeft: "3px" }}
          >
            Settings
          </span>
        </Typography>
        <Tabs
          value={this.state.tab}
          data-test-id="tabs"
          variant="scrollable"
          onChange={(e, v) => this.handleChange(e, v)}
          scrollButtons="auto"
          aria-label="scrollable tabs example"
          sx={{
            '& .MuiTabs-flexContainer': {
              justifyContent: 'space-between',
              display: 'flex',
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#C2B30A',
            },
          }}
        >
          <Tab
            sx={{
              textTransform: 'none',
              fontWeight: '700',
              color: '#ffffff',
              '&.Mui-disabled': {
                opacity: 1,
                pointerEvents: 'none',
                color: '#78716C',
              },
              width: "20%",
              '&.Mui-selected': {
                color: '#ffffff',
              },
            }}
            label="Profile" value="1" />
          <Tab label="Change Email"
            sx={{
              textTransform: 'none',
              color: '#ffffff',
              '&.Mui-disabled': {
                opacity: 1,
                pointerEvents: 'none',
                color: '#78716C',
              },
              fontWeight: '700',
              width: "20%",
              '&.Mui-selected': {
                color: '#ffffff',
              },
            }}
            value="2" />
          <Tab label="Change Password"
            sx={{
              textTransform: 'none',
              fontWeight: '700',
              color: '#ffffff',
              '&.Mui-disabled': {
                opacity: 1,
                color: '#78716C',
                pointerEvents: 'none',
              },
              width: "20%",
              '&.Mui-selected': {
                color: '#ffffff',
              },
            }}
            value="3" />
          <Tab label="Delete Account"
            sx={{
              textTransform: 'none',
              fontWeight: '700',
              color: '#DC2626',
              '&.Mui-disabled': {
                color: '#78716C',
                pointerEvents: 'none',
                opacity: 1,
              },
              width: "20%",
              '&.Mui-selected': {
                color: '#ffffff',
              },
            }}
            value="5"
          />
        </Tabs>
        <Box
          sx={{
            scrollbarWidth: 'none',
            height: '75%',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {this.state.tab == '2' &&
            <ChangeEmail
              navigation={this.props.navigation}
              id={this.props.id}
              history={this.props.history}
            />}
          {this.state.tab == '3' && <ChangePassword history={this.props.history} />}
          {this.state.tab == '5' && <DeleteAccount history={this.props.history} />}
          {this.state.tab == '1' && <UserProfile />}
        </Box>
      </Box>
    )
  }
}
